import { safeFetchAPI, safeWrap } from '@/api';
import { SAMPLE_MERGE_DATA, SAMPLE_OVERRIDE_DATA, userUtils, utils } from '@/helpers';
import { FullContact, OverrideContact, OverrideContactWithID, Wallet } from '@/types';
import { create } from 'zustand';

export type FullContactStoreState = {
	loading: boolean;
	contactLoaded: boolean;
	walletLoaded: boolean;
	loadedOverrides: boolean;
	contactError?: string;
	walletError?: string;

	wallet: Wallet | undefined;
	fullContact: FullContact | undefined;
	overrides: Record<string, OverrideContactWithID> | undefined;

	getContact: (contactID: string) => Promise<void>; // Fetches both the full contact and wallet

	updateContact: (contact: Partial<FullContact>) => void; // Updates the full contact
	updateWallet: (wallet: Partial<Wallet>) => void; // Updates the wallet

	clearContact: () => void; // Clears both the full contact and wallet
};

type OmitKeys = 'getContact' | 'clearContact' | 'updateContact' | 'updateWallet';
const defaults: Omit<FullContactStoreState, OmitKeys> = {
	loading: false,
	contactLoaded: false,
	walletLoaded: false,
	loadedOverrides: false,
	contactError: undefined,
	walletError: undefined,
	wallet: undefined,
	fullContact: undefined,
	overrides: undefined,
};

export const useFullContactStore = create<FullContactStoreState>((set, get) => ({
	...defaults,

	async getContact(contactID: string) {
		set({ loading: true, contactError: undefined, walletError: undefined });

		const [fullContact, contactError] = await safeFetchAPI<FullContact>(`/piis/:uid/${contactID}`, { forceToken: true });

		if (contactError) {
			utils.toaster?.addToast?.({ message: contactError, type: 'danger', placement: 'top' });
			set({ contactError, loading: false });
			return; // Don't fetch the wallet if the contact fetch failed
		} else {
			if (userUtils.debugMode()) console.log('Full contact:', fullContact);
			if (utils.isLocal() && fullContact) {
				fullContact.mrgV2 = SAMPLE_MERGE_DATA;
			}
			set({ fullContact, contactLoaded: true });
		}

		const [wallet, walletError] = await safeFetchAPI<Wallet>(`/searchWallet/bySrcID/:uid?srcID=${contactID}`, { forceToken: true });
		if (!wallet || walletError) {
			set({ walletError: 'Wallet not found', loading: false });
		} else {
			if (userUtils.debugMode()) console.log('Wallet:', wallet);
			set({ wallet, walletLoaded: true });
		}

		if (userUtils.isAdmin()) {
			safeWrap(async () => {
				let [overridesResp, _] = await safeFetchAPI<Record<string, OverrideContact>>(`/override/dump/:uid/${contactID}`, { forceToken: true });
				if (userUtils.debugMode()) console.log('Overrides:', overridesResp);
				const overrides: Record<string, OverrideContactWithID> = {};
				if (utils.isLocal() && !Object.keys(overrides).length) {
					overridesResp = SAMPLE_OVERRIDE_DATA;
				}
				for (const key in overridesResp) {
					overrides[key] = { ...overridesResp[key], id: key };
				}
				set({ overrides, loadedOverrides: true });
			});
		}

		set({ loading: false });
	},

	updateContact(contact) {
		const { fullContact } = get();
		if (!fullContact) return;

		set({ fullContact: { ...fullContact, ...contact } });
	},

	updateWallet(wallet) {
		const { wallet: currentWallet } = get();
		if (!currentWallet) return;

		set({ wallet: { ...currentWallet, ...wallet } });
	},

	clearContact() {
		set({ ...defaults });
	},
}));

export default useFullContactStore;
