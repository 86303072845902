import { safeFetchAPI } from '@/api';
import { LoyaltyTemplate } from '@/types';
import { create } from 'zustand';

export type LoyaltyTemplateStoreState = {
	loaded: boolean;
	loading: boolean;

	defaultForms: Record<string, string> | undefined;
	globalTemplate: LoyaltyTemplate | undefined;
	loyaltyTemplates: LoyaltyTemplate[];

	getLoyaltyTemplate: (id: string) => LoyaltyTemplate | undefined;

	loadLoyaltyTemplates: () => Promise<void>;
};

export const useLoyaltyTemplateStore = create<LoyaltyTemplateStoreState>((set, get) => ({
	loaded: false,
	loading: true,
	globalTemplate: undefined,
	loyaltyTemplates: [],
	defaultForms: undefined,

	getLoyaltyTemplate: (id: string) => get().loyaltyTemplates.find((template) => template.id === id),

	async loadLoyaltyTemplates() {
		const [loyaltyTemplates, error] = await safeFetchAPI<LoyaltyTemplate[]>('/loyalty/:uid');
		if (error || !loyaltyTemplates) {
			console.error(error);
			return;
		}
		const sortedTemplates = loyaltyTemplates.sort((a, b) => Number.parseInt(a.id ?? '') - Number.parseInt(b.id ?? ''));
		const globalTemplate = sortedTemplates[0];

		const defaultForms =
			loyaltyTemplates?.reduce(
				(prev, tmpl) => {
					tmpl.defaultStores?.forEach((id) => {
						if (tmpl.id) {
							prev[id] = tmpl.id;
						}
					});
					return prev;
				},
				{} as Record<string, string>,
			) ?? {};

		set({
			defaultForms,
			loyaltyTemplates: sortedTemplates,
			globalTemplate,
			loaded: true,
			loading: false,
		});
	},
}));

export default useLoyaltyTemplateStore;
